<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="600">
      <v-card class="pa-8">
        <v-row class="justify-end">
          <v-btn
            icon color="primary"
            @click="$emit('close-dialog-confirm-open-competition')"
          >
            <v-icon v-text="'close'" />
          </v-btn>
        </v-row>
        <v-row>
          <v-icon x-large color="pending" v-text="'warning_outline'" />
        </v-row>
        <v-row>
          <v-card-text>
            <div v-html="$t('project.actions.confirmOpenCompetition.message')" />
          </v-card-text>
        </v-row>
        <v-card-actions class="justify-space-around align-center">
          <v-btn
            color="primary" min-width="12rem" outlined dense
            @click="$emit('close-dialog-confirm-open-competition')" v-text="$t('project.actions.confirmOpenCompetition.openLater')"
          />
          <v-btn
            color="primary" min-width="12rem"
            @click="$emit('confirm-open-competition')" v-text="$t('common.open')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'ConfirmOpenCompetitionDialog',
}
</script>
